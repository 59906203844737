/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { computed, type MaybeRef } from 'vue'
import type Engagement from '~/src/Domain/Engagement/Engagement'
import type Phase from '~/src/Domain/Engagement/Phase'
import type Organisation from '~/src/Domain/Organisation/Organisation'
import useNavigation from '~/src/UserInterface/App/composables/Navigation/useNavigation'
import useEngagementNavigation from '~/src/UserInterface/Engagement/composables/useEngagementNavigation'

export default function useAccountsPayableNavigation(
  organisation: MaybeRef<Organisation>,
  engagement: MaybeRef<Engagement>,
  phase: MaybeRef<Phase>,
) {
  const { navigateTo } = useNavigation()
  const { getEngagementLocalePath } = useEngagementNavigation(organisation, engagement, phase)

  const accountsPayableIndexLocalePath = computed(() =>
    getEngagementLocalePath('accountsPayable-Index'),
  )
  const accountsPayableMasterDataLocalePath = computed(() =>
    getEngagementLocalePath('accountsPayable-masterData-Creditors'),
  )
  const accountsPayableOverviewLocalePath = computed(() =>
    getEngagementLocalePath('accountsPayable-Overview'),
  )
  const accountsPayableMultipleBankAccountsSameSupplierNameLocalePath = computed(() =>
    getEngagementLocalePath(
      'accountsPayable-MultipleBankAccountsSameSupplierName',
    ),
  )
  const accountsPayableMultipleSupplierNamesSameBankAccountLocalePath = computed(() =>
    getEngagementLocalePath(
      'accountsPayable-MultipleSupplierNamesSameBankAccount',
    ),
  )
  const accountsPayableExplorerLocalePath = computed(() =>
    getEngagementLocalePath('accountsPayable-explorer-AccountsPayables'),
  )

  const navigateToAccountsPayableIndexPage = async (): Promise<void> => {
    await navigateTo(accountsPayableIndexLocalePath.value)
  }

  const navigateToAccountsPayableOverviewPage = async (): Promise<void> => {
    await navigateTo(accountsPayableOverviewLocalePath.value)
  }

  return {
    accountsPayableIndexLocalePath,
    accountsPayableMasterDataLocalePath,
    accountsPayableOverviewLocalePath,
    accountsPayableMultipleBankAccountsSameSupplierNameLocalePath,
    accountsPayableMultipleSupplierNamesSameBankAccountLocalePath,
    accountsPayableExplorerLocalePath,
    navigateToAccountsPayableIndexPage,
    navigateToAccountsPayableOverviewPage,
  }
}
